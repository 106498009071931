import { ref } from "vue";
import _ from "lodash";
import { getCollection } from "@/services/collection.service";
import { getUserState } from "@/firebase";

let serviceInstance = null;

function createService() {
  const userPermissions = ref({});

  async function getUserPermissions(rebuild) {
    if (!_.isEmpty(userPermissions.value) && !rebuild) return;

    const userState = await getUserState();
    if (userState) {
      const users = await getCollection("users", userState.uid);
      if (!users.role) return;
      const {
        role: { value: userRole = "out" },
      } = users;
      const { permissions: rolePermissions } = await getCollection(
        "roles",
        userRole
      );
      userPermissions.value = rolePermissions.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});
    }
    return userPermissions.value;
  }

  function checkHasPermission(permissions = []) {
    if (!userPermissions.value) return false;
    return permissions.some(
      (permission) => userPermissions.value[permission] ?? false
    );
  }

  return {
    getUserPermissions,
    checkHasPermission,
  };
}

function useService() {
  if (!serviceInstance) serviceInstance = createService();

  return serviceInstance;
}

function resetAuthService() {
  serviceInstance = null;
}

export {
  createService as createAuthService,
  useService as useAuthService,
  resetAuthService,
};
