import { AccessPermissionsEnum } from "@/router/constants";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

export default [
  {
    path: "integrations",
    name: "integrations",
    component: load("integrations/Integrations"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_INTEGRATIONS],
      icon: "puzzle",
      menuItems: "leftSideMenu.integrations",
    },
  },
  {
    path: "integrations/select",
    name: "integrations:select",
    component: load("integrations/SelectIntegration"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_INTEGRATIONS],
      icon: "puzzle",
      menuItems: "leftSideMenu.integrations",
    },
  },
  {
    path: "integrations/pos/create",
    name: "integrations:pos:create",
    component: load("integrations/EditPos"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_INTEGRATIONS],
      icon: "puzzle",
      menuItems: "leftSideMenu.integrations",
    },
  },
  {
    path: "integrations/messenger/create",
    name: "integrations:messenger:create",
    component: load("integrations/EditMessenger"),
    meta: {
      requiresAuth: true,
      accessPermissions: [AccessPermissionsEnum.CAN_READ_INTEGRATIONS],
      icon: "puzzle",
      menuItems: "leftSideMenu.integrations",
    },
  },
];
