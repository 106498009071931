export default {
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка профиля"])},
    "firstname": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иван"])}
    },
    "lastname": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иванов"])}
    },
    "patronymic": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчество"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иванович"])}
    },
    "email": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ivan.ivanov", "@", "gmail.com"])}
    },
    "phone": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])}
    },
    "buttons": {
      "changePasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль"])},
      "bankDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковские реквизиты"])},
      "tipCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер карты для чаевых"])},
      "saveUserInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить данные пользователя"])}
    },
    "passwordChange": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])},
      "form": {
        "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий пароль"])},
        "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
        "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите новый пароль"])}
      },
      "buttons": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])}
      },
      "errors": {
        "require": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не может быть пустым"])},
        "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пароль должен содержать не менее ", _interpolate(_named("n")), " символов"])},
        "samePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли должны совпадать"])},
        "hasDigitMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы одну цифру"])},
        "hasUppercaseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы одну прописную букву"])},
        "hasLowercaseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы одну строчную букву"])},
        "hasSpecialSymbolsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы один специальный символ"])},
        "tooManyRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слишком много запросов"])},
        "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный текущий пароль"])}
      }
    }
  },
  "datapicker": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "timeplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать время"])},
    "dateplaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать дату"])}
  },
  "tasks": {
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки задачи"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка задачи на определенное время"])},
      "time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете настроить дату и время задачи"])}
      },
      "errors": {
        "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название задачи не может быть пустым"])},
        "nameMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Название задачи не может быть меньше ", _interpolate(_named("n")), " символов"])},
        "descriptionNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание задачи не может быть пустым"])},
        "descriptionMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Описание задачи не может быть меньше ", _interpolate(_named("n")), " символов"])},
        "pointsNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите хотя бы одну точку"])},
        "dateNotBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть после конечной"])},
        "dateNotAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть до начальной"])}
      }
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование задачи"])}
    }
  },
  "news": {
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки новости"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка новости на определенное время"])},
      "time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете настроить дату и время новости"])}
      },
      "errors": {
        "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название новости не может быть пустым"])},
        "nameMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Название новости не может быть меньше ", _interpolate(_named("n")), " символов"])},
        "descriptionNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание новости не может быть пустым"])},
        "descriptionMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Описание новости не может быть меньше ", _interpolate(_named("n")), " символов"])},
        "pointsNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите хотя бы одну точку"])},
        "dateNotBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть после конечной"])},
        "dateNotAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть до начальной"])}
      }
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование новости"])}
    }
  },
  "feedbacks": {
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки обратной связи"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка обратной связи на определенное время"])},
      "time": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете настроить дату и время обратной связи"])}
      },
      "errors": {
        "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название обратной связи не может быть пустым"])},
        "nameMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Название обратной связи не может быть меньше ", _interpolate(_named("n")), " символов"])},
        "descriptionNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание обратной связи не может быть пустым"])},
        "descriptionMinLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Описание обратной связи не может быть меньше ", _interpolate(_named("n")), " символов"])},
        "pointsNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите хотя бы одну точку"])},
        "dateNotBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть после конечной"])},
        "dateNotAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть до начальной"])}
      }
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование обратной связи"])}
    }
  },
  "multiselect": {
    "current": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Выбрано 0 точек"]), _normalize(["Выбрана ", _interpolate(_named("n")), " точка"]), _normalize(["Выбрано ", _interpolate(_named("n")), " точки"]), _normalize(["Выбрано ", _interpolate(_named("n")), " точек"])])},
    "allpoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбраны все точки"])}
  },
  "phone": {
    "countrySelector": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код страны"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите страну"])}
    },
    "phoneInput": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
      "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пример:"])}
    }
  },
  "button": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
  },
  "toast": {
    "successSaveSettings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Настройки ", _interpolate(_named("target")), " сохранены!"])},
    "errorSaveSettings": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ошибка сохранения настроек ", _interpolate(_named("target")), "!"])},
    "deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " удален!"])},
    "word": {
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])}
    }
  },
  "agents": {
    "nav_buttons": {
      "agents_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список агентов"])}
    }
  },
  "merchants": {
    "nav_buttons": {
      "merchants_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список клиентов"])}
    }
  },
  "crm": {
    "nav_buttons": {
      "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты"])},
      "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассылка"])},
      "feedbacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзывы"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
      "triggers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическая рассылка"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказы"])}
    },
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])}
  },
  "staff": {
    "buttonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка персонала"])},
    "buttonCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вызов персонала"])},
    "buttonTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи персонала"])},
    "buttonNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новостная лента"])},
    "buttonNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассылка"])},
    "buttonFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])},
    "newButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "new": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый сотрудник"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте нового сотрудника"])}
    },
    "edit": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать сотрудника"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внести изменения"])}
    },
    "errors": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный логин"])},
      "emailNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не найден"])},
      "emailNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не должен быть пустым"])},
      "emailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат email"])},
      "surnameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия не должна быть пустой"])},
      "nameNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя не должно быть пустым"])},
      "minLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле должно содержать не менее 3 символов"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный пароль"])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный пароль"])},
      "passwordNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен быть пустым"])},
      "resetNotEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы не можем найти пользователя с этим email адресом."])},
      "phone": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона не может быть пустым"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте длину телефона"])}
      }
    }
  },
  "pselection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор программы лояльности"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите и настройте вашу программу лояльности"])},
    "radioButton": {
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонус"])}
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
    "discount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дисконтная программа лояльности"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка вашей прямой скидки клиенту"])},
      "levels": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка уровней"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите необходимое количество уровней, их сумму и процент скидки"])},
        "row": {
          "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название уровня"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма от"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма до"])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка, %"])}
        },
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить уровень"])}
      },
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец карты"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "untilDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До скидки"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя Фамилия"])}
      },
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки скидок сохранены!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения настроек скидок!"])}
      }
    },
    "bonus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонусная система лояльности"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка для вашей бонусной программы"])},
      "levels": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка уровней"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите необходимое количество уровней, их сумму и процент скидки"])},
        "row": {
          "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название уровня"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма от"])},
          "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма до"])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонус, %"])}
        },
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить уровень"])}
      },
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])},
        "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец карты"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "untilBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До бонуса"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя Фамилия"])}
      },
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки бонусов сохранены!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения настроек бонусов!"])}
      }
    },
    "coupon": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купонная программа лояльности"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете настроивать ваш купон и количество действий для его получения, повторяемый он или нет"])},
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])},
        "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец карты"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "untilBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До бонуса"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя Фамилия"])},
        "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coupon"])},
        "accumulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["накопление"])},
        "congratulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["поздравление"])}
      }
    },
    "clubcard": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клубная карта"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпросмотр клубной карты"])},
      "walletCard": {
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])},
        "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
        "cardOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец карты"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "untilBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До бонуса"])},
        "FIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя Фамилия"])},
        "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["купон"])}
      }
    }
  },
  "vclient": {
    "submenu": {
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])},
      "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
      "qr-catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-каталог"])}
    },
    "qrCatalog": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-каталог"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройте свой QR-каталог"])},
      "colors": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет текста"])},
        "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет заголовка"])},
        "relief-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет фона"])},
        "relief-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет категории"])},
        "relief-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет текста цены"])},
        "relief-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет фона цены"])}
      },
      "logoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип для каталога"])},
      "logoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип текст для каталога"])},
      "downloadLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка QR Code"])},
      "previewLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпросмотр"])},
      "previewLinkHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все изменения будут доступны после нажатия на кнопку \"Опубликовать\""])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-каталог настройки сохранены!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения настройки QR-каталога!"])}
      }
    },
    "wallet": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet для карт"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка Wallet для карт"])},
      "cardColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет карты"])},
      "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет текста"])},
      "titleColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет заголовка"])},
      "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип для Wallet"])},
      "logoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст логотипа для Wallet"])},
      "centralImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центральное изображение для Wallet"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпросмотр"])},
      "toast": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки Wallet сохранены!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при сохранении настроек Wallet!"])}
      }
    }
  },
  "leftSideMenu": {
    "agents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агенты"])},
    "averageСheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средний чек"])},
    "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-каталог"])},
    "clientData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные клиента"])},
    "crm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRM"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])},
    "for-client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для клиентов"])},
    "for-staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для персонала"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
    "integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграции"])},
    "levelRetention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удержание клиентов"])},
    "levelSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удовлетворённость клиентов"])},
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "merchants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты"])},
    "pointsOfSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точки продаж"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "pselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Программа лояльности"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонал"])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка"])},
    "topSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ продаж"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта Wallet"])}
  },
  "application": {
    "imageForBothTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the downloaded image for both themes in the app"])}
  },
  "locale": {
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укр"])},
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рус"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eng"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать"])},
    "forgotQuery": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите e-mail, указанный при регистрации. Мы отправим вам ссылку для сброса пароля."])},
      "messageSuccessReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы отправили ссылку для восстановления пароля на указанный Email."])}
    },
    "pleaseSignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, войдите в ваш аккаунт"])},
    "buttonLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "buttonReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])},
    "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запомнить меня"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
    "errors": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный пароль"])},
      "emailNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта не найдена"])},
      "emailNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не должен быть пустым"])},
      "emailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта недействительна"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный пароль"])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный пароль"])},
      "passwordNotEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен быть пустым"])},
      "resetNotEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы не можем найти пользователя с этим электронным адресом."])}
    }
  },
  "menu": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основна сторінка"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])}
  },
  "form": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регестрация"])}
  },
  "main": {
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ", хотите начать настройку?"])},
    "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
    "new_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["немає повідомлень"]), _normalize([_interpolate(_named("count")), " повідомлення"]), _normalize([_interpolate(_named("count")), " повідомлення"]), _normalize([_interpolate(_named("count")), " повідомлень"])])}
  },
  "nav": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "loyalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Программа лояльности"])},
    "bank-links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковские ссылки"])}
  },
  "points": {
    "workTime": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим работы"])}
    },
    "titlenameNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая точка продаж"])},
    "subtitleNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг 1. Добавьте новую точку продаж. После добавления информации нажмите “Продолжить”."])},
    "titlenameEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать точку"])},
    "subtitleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отредактируйте точку, чтобы клиенты могли связаться с вами"])},
    "phone": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить номер телефона"])},
      "addPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить телефон"])}
    },
    "btnNewPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить точку продаж"])},
    "table": {
      "header": {
        "point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точки продаж"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "qrQatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-каталог"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТЕЛЕФОН"])},
        "viber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIBER"])},
        "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHATSAPP"])},
        "telegram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TELEGRAM"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДЕЙСТВИЯ"])}
      },
      "body": {
        "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы еще не добавили ни одной точки продаж"])}
      },
      "delete": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить контакт"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить контакт? Все данные будут удалены"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
      }
    },
    "address": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начните вводить свой адрес"])}
    },
    "title": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название"])}
    },
    "subdomain": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменное имя"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите доменное имя"])}
    },
    "errors": {
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес не может быть пустым"])},
      "subdomain": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменное имя не может быть пустым"])},
        "latin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допускаются только латинские символы"])},
        "exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменное имя уже существует"])}
      },
      "domain_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакт не сохранен. Такое доменное имя уже существует"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название не может быть пустым"])},
      "title_symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле имеет ошибочный формат"])},
      "phone": {
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона не может быть пустым"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат телефона"])},
        "unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данный Телефон уже присутствует в данной точке продаж"])},
        "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Такой номер не валиден"])}
      }
    },
    "catalog": {
      "point": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите точку продаж"])}
      },
      "copy": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cкопировать каталог из предыдущей точки?"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])}
      }
    }
  },
  "catalog": {
    "sale_channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точки продаж"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товары и услуги"])},
    "qr_catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний вид каталога"])},
    "point": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите точку продаж"])},
      "not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед началом работы с каталогом выберите точку"])},
      "list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед началом работы с каталогом создайте точку"])}
    }
  },
  "products": {
    "errors": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не может быть пустым"])}
    }
  },
  "_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}